<template>
  <Head>
    <Meta v-if="noFollow" name="robots" content="noindex,nofollow" />
    <template v-if="blok.meta">
      <Meta
        v-if="blok.meta.description"
        name="description"
        :content="blok.meta.description"
      />
      <Meta
        v-if="blok.meta.og_title || blok.meta.title"
        name="og:title"
        :content="blok.meta.og_title || blok.meta.title"
      />
      <Meta
        v-if="blok.meta.og_image"
        name="og:image"
        :content="blok.meta.og_image"
      />
      <Meta
        v-if="blok.meta.og_description || blok.meta.description"
        name="og:description"
        :content="blok.meta.og_description || blok.meta.description"
      />
      <Meta
        v-if="blok.meta.twitter_title || blok.meta.title"
        name="twitter:title"
        :content="blok.meta.twitter_title || blok.meta.title"
      />
      <Meta
        v-if="blok.meta.twitter_image"
        name="twitter:image"
        :content="blok.meta.twitter_image"
      />
      <Meta
        v-if="blok.meta.twitter_description || blok.meta.description"
        name="twitter:description"
        :content="blok.meta.twitter_description || blok.meta.description"
      />
    </template>
    <template v-if="metaTag">
      <Meta
        v-for="item in metaTag"
        :key="item._uid"
        :name="item.name"
        :content="item.content"
      />
    </template>
    <template v-if="linkTag">
      <Link
        v-for="item in linkTag"
        :key="item._uid"
        :rel="item.rel"
        :href="item.href"
        :hreflang="item.hreflang ? item.hreflang : 'false'"
      />
    </template>
    <Link rel="canonical" :href="canonicalURL" />
  </Head>
</template>

<script lang="ts" setup>
import { ExternalScript, LinkTag, MetaTag, PageHeadBlokProps } from '@/types';

// Props
const props = defineProps<{
  blok: PageHeadBlokProps;
  date: string;
  name: string;
  slug: string;
}>();
// Boolean for environment conditionals
// const isDev =
//  window.location.hostname.split('.').slice(0, -2).join('.') === 'development';
// 'marketing.staging';

// Check if production branch
const isDevBranch =
  useRuntimeConfig().public.currentBranch === 'staging' ||
  useRuntimeConfig().public.currentBranch === 'dev';

const noFollow = computed(() => {
  return props.blok.noindex_nofollow || isDevBranch;
});

// Keep slug prop reactive by setting as ref
const fullSlug = ref(props.slug);

// Keep canonical URL prop reactive by setting as ref
const canonicalURL = ref(props.blok.canonical_url);

// Check if defined within Storyblok
if (!canonicalURL.value) {
  // check if slug is home
  if (fullSlug.value === 'home') {
    fullSlug.value = '';
  }
  // Concatinate siteURL and slug
  canonicalURL.value = useRuntimeConfig().public.siteURL + fullSlug.value;
}

// Computed
// Filter by name the components added to custom_tags
const linkTag = computed(() => {
  const tags = props.blok.custom_tags
    ? props.blok.custom_tags.filter((i) => i.component === 'LinkTag')
    : [];
  return tags as LinkTag[];
});
const metaTag = computed(() => {
  const tags = props.blok.custom_tags
    ? props.blok.custom_tags.filter((i) => i.component === 'MetaTag')
    : [];
  return tags as MetaTag[];
});
const scriptTag = computed(() => {
  const tags = props.blok.custom_tags
    ? props.blok.custom_tags.filter((i) => i.component === 'ExternalScript')
    : [];
  return tags as ExternalScript[];
});

// Create an array of ExternalScript components
const externalScript = scriptTag.value
  ? scriptTag.value.map((script) => ({
      async: !script.place_in_body,
      body: script.place_in_body,
      id: script.id,
      src: script.src,
    }))
  : [];

// Create an array of JSONMarkup components
const jsonMarkup = props.blok.schema_block
  ? props.blok.schema_block.map((json) => ({
      body: json.place_in_body,
      children: json.code,
      type: 'application/ld+json',
    }))
  : [];

// OneTrust Cookies Consent Notice...note the load order below, as it needs to load first
const oneTrustScripts = [
  {
    'data-domain-script': 'dc042f2d-6f77-4ea5-8ff4-dabe648fff54',
    src: 'https://cdn.cookielaw.org/scripttemplates/otSDKStub.js',
  },
  {
    children: 'function OptanonWrapper(){}',
  },
];

const optimizelyScripts = [
  {
    src: 'https://cdn.optimizely.com/js/24706770096.js',
  },
];

// Check if Segment environment variable is defined before adding to script to page
const segmentScript = useRuntimeConfig()?.public?.segmentID
  ? [
      {
        children: `!function(){var analytics=window.analytics=window.analytics||[];if(!analytics.initialize)if(analytics.invoked)window.console&&console.error&&console.error("Segment snippet included twice.");else{analytics.invoked=!0;analytics.methods=["trackSubmit","trackClick","trackLink","trackForm","pageview","identify","reset","group","track","ready","alias","debug","page","once","off","on","addSourceMiddleware","addIntegrationMiddleware","setAnonymousId","addDestinationMiddleware"];analytics.factory=function(e){return function(){var t=Array.prototype.slice.call(arguments);t.unshift(e);analytics.push(t);return analytics}};for(var e=0;e<analytics.methods.length;e++){var key=analytics.methods[e];analytics[key]=analytics.factory(key)}analytics.load=function(key,e){var t=document.createElement("script");t.type="text/javascript";t.async=!0;t.src="https://cdn.segment.com/analytics.js/v1/" + key + "/analytics.min.js";var n=document.getElementsByTagName("script")[0];n.parentNode.insertBefore(t,n);analytics._loadOptions=e};analytics._writeKey="${
          useRuntimeConfig().public.segmentID
        }";analytics.SNIPPET_VERSION="4.15.2";}}();`,
      },
    ]
  : [];

// Scripts for Salesforce Chat
const salesforceScripts = [
  {
    src: 'https://shiftkey.my.salesforce-sites.com/chat/resource/MarketingChatbotScripts',
    tagPosition: 'bodyClose',
    type: 'text/javascript',
  },
];

const gtmScripts = [
  {
    children: `window.addEventListener("message", function(event) { if(event.data.type === 'hsFormCallback' && event.data.eventName === 'onFormReady') { window.dataLayer.push({ 'event': 'hubspot-form-ready', 'hs-form-guid': event.data.id }); } });`,
  },
];

const hubspotScripts = [
  {
    id: 'hubspot-script',
    src: '//js.hsforms.net/forms/embed/v2.js',
    type: 'text/javascript',
  },
];

const prerenderScripts = [
  {
    children:
      '{  "prerender": [{    "source": "document",    "where": {      "and": [        { "selector_matches": ".immediate" },        { "not": {"selector_matches": ".do-not-prerender"}}      ]    },    "eagerness": "immediate"  }]}',
    type: 'speculationrules',
  },
];

// Merge the ExternalScript and JSONMarkup arrays
const mergedScripts: any[] = [
  ...oneTrustScripts, // Needs to load first
  ...optimizelyScripts,
  ...externalScript,
  ...jsonMarkup,
  ...segmentScript,
  ...gtmScripts,
  ...salesforceScripts,
  ...hubspotScripts,
  ...prerenderScripts,
];

// Page-level title...titleTemplate used to be handled in nuxt.config
// Every page title will be prefixed with: ShiftKey -
useHead({
  link: [
    {
      as: 'script',
      'data-domain-script': 'dc042f2d-6f77-4ea5-8ff4-dabe648fff54',
      href: 'https://cdn.cookielaw.org/scripttemplates/otSDKStub.js',
      rel: 'preload',
    },
    {
      as: 'script',
      href: '//cdn.optimizely.com/js/24706770096.js',
      rel: 'preload',
    },
    {
      as: 'script',
      href: '//logx.optimizely.com',
      rel: 'preconnect',
    },
  ],
  script: mergedScripts,
  titleTemplate: `${
    props.blok.meta?.title ? `${props.blok.meta.title}` : 'ShiftKey'
  }`,
});

onMounted(() => {
  const slug = window.location.pathname;
  if (process.client && window?.analytics) {
    if (window.analytics.load && typeof window.analytics.load === 'function') {
      window.analytics.load(useRuntimeConfig().public.segmentID);
    }
  }
  if (localStorage.getItem('time')) {
    const currentTime = new Date().getTime();
    const storedTime = localStorage.getItem('time')
      ? parseInt(localStorage.getItem('time') || '0')
      : 0;
    const timeDifference = currentTime - storedTime;
    const minutesDifference = Math.floor(timeDifference / (1000 * 60));
    if (minutesDifference > 1) {
      localStorage.removeItem('previousPage');
    }
    localStorage.setItem('time', new Date().getTime().toString());
  } else {
    localStorage.setItem('time', new Date().getTime().toString());
  }
  if (window.analytics.page && typeof window.analytics.page === 'function') {
    window.analytics.page({
      name: props.blok.segment_page_name || props.name,
      path: slug,
      referrer: localStorage.getItem('previousPage')
        ? localStorage.getItem('previousPage')
        : document.referrer,
      title: props.blok.meta?.title ? `${props.blok.meta.title}` : 'ShiftKey',
      url: canonicalURL.value,
    });
  }
});
</script>

<style lang="scss">
/* stylelint-disable-next-line selector-class-pattern */
.embeddedServiceHelpButton .helpButton .uiButton {
  background-color: #0abc28;
  font-family: 'Salesforce Sans', sans-serif;
}

/* stylelint-disable-next-line selector-class-pattern */
.embeddedServiceHelpButton .helpButton .uiButton:focus {
  outline: 1px solid #0abc28;
}
@font-face {
  font-family: 'Salesforce Sans';
  src: url('https://c1.sfdcstatic.com/etc/clientlibs/sfdc-aem-master/clientlibs_base/fonts/SalesforceSans-Regular.woff')
      format('woff'),
    url('https://c1.sfdcstatic.com/etc/clientlibs/sfdc-aem-master/clientlibs_base/fonts/SalesforceSans-Regular.ttf')
      format('truetype');
}
</style>
